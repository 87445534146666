.card-type-payment.is-checked {
  border-color: var(--focus);
}

.card-type-payment.is-dashed {
  border: 4px dashed #222020;
}

.card-type-payment.is-bordered {
  border-color: #76b0ca;
}

.card-type-payment .card-header {
  border: none;
}

.card-type-payment.disabled {
  cursor: not-allowed;
}

.card-type-payment.disabled .card-header {
  color: #8f8c8c !important;
  background-color: #d8d6d6;
  pointer-events: none; /* adicione isso para desabilitar o clique no título */

}


