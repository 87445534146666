dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}
dialog:not([open]) {
  display: none;
}
.dialog {
  border: none;
  box-shadow: 0 9px 46px 8px rgb(0 0 0 / 14%), 0 11px 15px -7px rgb(0 0 0 / 12%), 0 24px 38px 3px rgb(0 0 0 / 20%);
  width: 280px;
}
.dialog-modal {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.64);
}
.dialog-content {
  padding: 20px 24px 24px 24px;
  color: rgba(0,0,0, 0.54);
  width: 100%;
}
.dialog-modal-main {
  background: transparent;
  box-shadow: none;
  position: fixed;
  width: 100vw!important;
  height: 100vh!important;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
}
.dialog-modal-main .dialog-modal-main-files {
  display: grid;
  grid-template-columns: 10% auto 10%;
  grid-column-gap: 16px;
  align-items: center;
  justify-items: center;
  height: 100%;
}
.dialog-modal-main .dialog-modal-main-files .dialog-modal-main-canvas {
  height: 500px;
  width: 100%;
  margin-top: 8%;
}
.dialog-modal-main .dialog-modal-main-files .dialog-modal-main-img {
  height: 100%;
  width: 100%;
}
.dialog-modal-main .dialog-modal-main-files .dialog-modal-main-img-cover {
  height: 100%;
  object-fit: contain;
  width: 100%;
  background: no-repeat center;
  background-size: contain;
}
.dialog-modal-main .dialog-modal-main-files .arrow-next, .dialog-modal-main .dialog-modal-main-files .arrow-prev {
  font-size: 40px;
  color: #FFFFFF;
  cursor: pointer;
}
.dialog-modal-main .dialog-circles {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 24px;
}
.dialog-modal-main .dialog-circles .dialog-circle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
}
.dialog-modal-main .dialog-circles .dialog-circle {
  font-size: 15px;
  margin-left: 24px;
  background-color: #CDCDCD;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  transition: all .5s;
}
.dialog-modal-main .dialog-circles .dialog-circle.selected {
  background-color: var(--focus);
  color: #FFF
}
.dialog-modal-main .dialog-circles .dialog-circle:first-of-type {
  margin-left: 0;
}

@media (min-width: 768px) {
  .dialog {
      width: 580px;
  }
}
