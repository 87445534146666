.critical-events-container {
  position: fixed;
  z-index: 155;
  right: -30px;
  top: 0;
  height: 100vh;
  -webkit-transform: translate(500px);
  transform: translate(500px);
  transition: all 0.5s;
  box-shadow: -0.46875rem 0 2.1875rem rgb(9 1 1 / 3%), -0.9375rem 0 1.40625rem rgb(9 1 1 / 3%), -0.25rem 0 0.53125rem rgb(9 1 1 / 5%), -0.125rem 0 0.1875rem rgb(9 1 1 / 3%);
}

.critical-events-container.is-open {
  -webkit-transform: translate(0);
  transform: translate(0);
  right: 0;
}

.critical-events-inner {
  background: #fff;
  width: 500px;
  height: 100vh;
  padding: 0;
}

.critical-events__options-wrapper .critical-events_heading {
  font-size: 1.1rem;
  color: #444;
  margin: 0;
  background: #f8f9fa;
  padding: 0.75rem 1.5rem;
  border-bottom: #ccc solid 1px;
  border-top: #ccc solid 1px;
  display: flex;
  align-items: center;
  align-content: center;
}

.critical-events__options-wrapper .critical-events_heading:first-child {
  border-top: 0;
}

.btn-open-critical-events {
  border-radius: 50px;
  position: absolute;
  left: -114px;
  bottom: 80px;
  padding: 0;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
  display: block;
  box-shadow: 0 0.46875rem 2.1875rem rgb(9 1 1 / 3%), 0 0.9375rem 1.40625rem rgb(9 1 1 / 3%), 0 0.25rem 0.53125rem rgb(9 1 1 / 5%), 0 0.125rem 0.1875rem rgb(9 1 1 / 3%);
  margin-top: -27px;
}

.btn-open-critical-events svg {
  top: 50%;
  left: 50%;
  position: absolute;
  margin: -0.5em 0 0 -0.5em;
}
