@keyframes loading {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading {
  animation: loading 2s linear infinite;
}
